import type { RouteRecordRaw } from "vue-router";

const notificationRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/setup/notification",
    name: "admin-setup-notification",
    component: () => import("@/views/admin/setup/notification/Notification.vue"),
    meta: {
      pageTitle: "notification",
      breadcrumbs: ["notification"],
    },
  },
];

export default notificationRoute;
