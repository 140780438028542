import type { RouteRecordRaw } from "vue-router";

const applicantRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/applicant",
    name: "applicant",
    component: () => import("@/views/admin/applicant/ApplicantList.vue"),
    meta: {
      pageTitle: "applicant",
      breadcrumbs: ["applicant"],
    },
  },
  {
    path: "/admin/applicant-profile",
    name: "applicant-profile",
    component: () => import("@/views/admin/applicant/ApplicantProfile.vue"),
    meta: {
      pageTitle: "applicant"
    }
  },
  {
    path: "/admin/admin-applicant-add-required-document",
    name: "admin-applicant-add-document",
    component: () => import("@/views/admin/applicant/ApplicantDocument.vue"),
    meta: {
      pageTitle: "applicant"
    }
  }
];

export default applicantRoute;
