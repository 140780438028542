import type { RouteRecordRaw } from "vue-router";

const registrationNumberRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/setup/reg-number-pattern",
    name: "admin-setup-reg-number-pattern",
    component: () =>
      import(
        "@/views/admin/setup/registration_number/RegistrationNumberList.vue"
      ),
    meta: {
      pageTitle: "registrationNumberPattern",
      breadcrumbs: ["setupPPDB", "registrationNumberPattern"],
    },
  },
  {
    path: "/admin/setup/admission/reg-number-pattern/edit",
    name: "admin-setup-reg-number-pattern-edit",
    component: () =>
      import(
        "@/views/admin/setup/registration_number/RegistrationNumberEdit.vue"
      ),
    meta: {
      pageTitle: "registrationNumberPattern",
      breadcrumbs: ["setupPPDB", "registrationNumberPattern", "edit"],
    },
  },
];

export default registrationNumberRoute;
