import type { RouteRecordRaw } from "vue-router";

const importRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/management/import",
    name: "import",
    component: () => import("@/views/admin/management/import/ImportList.vue"),
    meta: {
      pageTitle: "import",
      breadcrumbs: ["import"],
    },
  },
];

export default importRoute;
