import type { RouteRecordRaw } from "vue-router";

const exportRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/management/export",
    name: "export",
    component: () => import("@/views/admin/management/export/ExportList.vue"),
    meta: {
      pageTitle: "export",
      breadcrumbs: ["export"],
    },
  },
];

export default exportRoute;
