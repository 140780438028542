import type { RouteRecordRaw } from "vue-router";

const packageCostRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/package-cost",
        name: "admin-master-packageCost",
        component: () => import("@/views/admin/master/packageCost/PackageCostList.vue"),
        meta: {
            pageTitle: "packageCost",
            breadcrumbs: ["masterData", "packageCost"],
        },
    },
    {
        path: "/admin/master/package-cost/create",
        name: "admin-master-packageCost-create",
        component: () => import("@/views/admin/master/packageCost/PackageCostCreate.vue"),
        meta: {
            pageTitle: "packageCost",
            breadcrumbs: ["masterData", "packageCost", "create"]
        },
    },
    {
        path: "/admin/master/package-cost/update",
        name: "admin-master-packageCost-update",
        component: () => import("@/views/admin/master/packageCost/PackageCostUpdate.vue"),
        meta: {
            pageTitle: "packageCost",
            breadcrumbs: ["masterData", "packageCost", "update"]
        },
    },
];

export default packageCostRoute;
