import { ref } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {saveStoreApplication} from "@/core/helpers/helpers";

export interface User {
  username: string;
  password: string;
  id: string;
  name: string;
  email: string;
  access_token: string;
  is_admin: Number;
  sso_id: Number;
  data: Token;
}

export interface Token{
  access_token: string;
}

export const useAuthStore = defineStore("auth", () => {
  const errors = ref({});
  const user = ref<User>({} as User);
  const isAuthenticated = ref(!!JwtService.getToken());

  function setAuth(authUser: User) {
    isAuthenticated.value = true;
    user.value = authUser;
    errors.value = {};
    saveStoreApplication('profileUser', authUser.data)
    JwtService.saveToken(user.value.data.access_token);
  }

  function setError(error: any) {
    errors.value = { ...error };
  }

  function purgeAuth() {
    isAuthenticated.value = false;
    user.value = {} as User;
    errors.value = [];
    window.localStorage.removeItem('profileUser')
    JwtService.destroyToken();
  }

  function login(credentials: User) {
    return ApiService.post("system-admin/auth", credentials, "public")
      .then(({ data }) => {
        setAuth(data.payload);
      })
      .catch(({ response }) => {
        setError(response.data);
      });
  }

  function loginApplicant(credentials: User){
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = undefined;
    return ApiService.post("institution/auth", credentials, "public")
      .then(({data}) => {
        setAuth(data.payload);
      })
      .catch(({response}) => {
        setError(response.data);
      });
  }

  async function checkAuth(token: Token) {
    // Set header Authorization dengan Bearer token
    ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${token.access_token}`;

    try {
      const { data } = await ApiService.post("institution/auth/check", {}, "public");
      setAuth(data.payload);
    } catch (error: any) {
      setError(error.response.data);
    }
  }

  function logout() {
    window.localStorage.removeItem('menu')
    window.localStorage.removeItem('profileUser')
    purgeAuth();
  }

  function register(credentials: User) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        setAuth(data);
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function forgotPassword(email: string) {
    return ApiService.post("forgot_password", email)
      .then(() => {
        setError({});
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function verifyAuth() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    //   ApiService.post("verify_token", { api_token: JwtService.getToken() })
    //     .then(({ data }) => {
    //       setAuth(data);
    //     })
    //     .catch(({ response }) => {
    //       setError(response.data.errors);
    //       purgeAuth();
    //     });
    // } else {
    //   purgeAuth();
    }
  }

  return {
    errors,
    user,
    isAuthenticated,
    login,
    loginApplicant,
    checkAuth,
    logout,
    register,
    forgotPassword,
    verifyAuth,
  };
});
