import type { RouteRecordRaw } from "vue-router";

const periodRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/setup/period",
        name: "admin-master-period",
        component: () => import("@/views/admin/setup/period/PeriodList.vue"),
        meta: {
            pageTitle: "period",
            breadcrumbs: ["setupPPDB", "period"],
        },
    },
    {
        path: "/admin/setup/period/create",
        name: "admin-setup-period-create",
        component: () => import("@/views/admin/setup/period/PeriodCreate.vue"),
        meta: {
            pageTitle: "period",
            breadcrumbs: ["setupPPDB", "period", "create"],
        }
    },
    {
        path: "/admin/setup/period/update",
        name: "admin-setup-period-update",
        component: () => import("@/views/admin/setup/period/PeriodUpdate.vue"),
        meta: {
            pageTitle: "period",
            breadcrumbs: ["setupPPDB", "period", "update"],
        }
    },
    // {
    //     path: "/admin/setup/period/batches/update",
    //     name: "admin-setup-period-batches-update",
    //     component: () => import("@/views/admin/setup/period/period_batches/PeriodBatchesUpdate.vue"),
    //     meta: {
    //         pageTitle: "periodBatch",
    //         breadcrumbs: ["setupPPDB", "period", "batch", "update"],
    //     }
    // },
    // {
    //     path: "/admin/setup/period/batches/copy",
    //     name: "admin-setup-period-batches-copy",
    //     component: () => import("@/views/admin/setup/period/period_batches/PeriodBatchesCreate.vue"),
    //     meta: {
    //         pageTitle: "periodBatch",
    //         breadcrumbs: ["setupPPDB", "period", "batch", "copy"],
    //     }
    // }
];

export default periodRoute;
