import type { RouteRecordRaw } from "vue-router";

const configurationRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/setup/configuration/apps",
    name: "admin-setup-configuration-apps",
    component: () => import("@/views/admin/setup/configuration/application/ConfigurationList.vue"),
    meta: {
      pageTitle: "applicationConfig",
      breadcrumbs: ["setupApps", "applicationConfig"],
    },
  },
  {
    path: "/admin/setup/configuration/setup",
    name: "admin-setup-configuration-setup",
    component: () => import("@/views/admin/setup/configuration/setup/SetupList.vue"),
    meta: {
      pageTitle: "setup",
      breadcrumbs: ["setupApps", "setup"],
    },
  },
  {
    path: "/admin/setup/configuration/general",
    name: "admin-setup-configuration-general",
    component: () => import("@/views/admin/setup/configuration/general/GeneralList.vue"),
    meta: {
      pageTitle: "general",
      breadcrumbs: ["setupApps", "general"],
    },
  },
  {
    path: "/admin/setup/configuration/general/update-html",
    name: "admin-setup-configuration-general-update-html",
    component: () => import("@/views/admin/setup/configuration/general/GeneralUpdateHTML.vue"),
    meta: {
      pageTitle: "general",
      breadcrumbs: ["setupApps", "general", "update"],
    },
  },
  {
    path: "/admin/setup/configuration/general/create",
    name: "admin-setup-configuration-general-create",
    component: () => import("@/views/admin/setup/configuration/general/GeneralCreate.vue"),
    meta: {
      pageTitle: "general",
      breadcrumbs: ["setupApps", "general", "create"],
    },
  },
];

export default configurationRoute;
