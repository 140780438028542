import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useConfigStore } from '@/stores/config'
import { useMenuStore } from '@/stores/menu'
import SystemRoute from '@/router/admin/system/system'
import entranceRoute from '@/router/admin/master/entrance'
import classProgramRoute from '@/router/admin/master/class_program'
import departmentRoute from '@/router/admin/master/department'
import testLocationRoute from '@/router/admin/master/test_location'
import documentRoute from '@/router/admin/master/document'
import registrationNumberRoute from '@/router/admin/setup/registration_number'
import progressRoute from '@/router/admin/setup/progress'
import dashboardRoute from '@/router/admin/dashboard/dashboard'
import applicantRoute from '@/router/admin/applicant/applicant'
import systemRoute from '@/router/admin/system/system'
import appsRegisterRoute from '@/router/apps/auth/register'
import referenceRoute from '@/router/admin/master/reference'
import appsLoginRoute from '@/router/apps/auth/login'
import appsResetRoute from '@/router/apps/auth/forgot_password'
import institutionClassesRoute from '@/router/admin/master/institution_classes'
import periodRoute from '@/router/admin/setup/period'
import i18n from '@/core/plugins/i18n'
import packageCostRoute from '@/router/admin/master/package_cost'
import biodataRoute from '@/router/user/biodata/biodata'
import cmsRoute from '@/router/admin/cms/cms'
import typeReceptionRoute from '@/router/admin/master/type_reception'
import billRoute from '@/router/admin/bill/bill'
import applicantBillRoute from '@/router/user/bill/bill'
import applicantDocumentRoute from '@/router/user/document/document'
import announcementAdminRoute from '@/router/admin/announcement/announcement'
import progressFoundationRoute from '@/router/foundation/progress/progress'
import institutionFoundationRoute from '@/router/foundation/institution/institution'
import periodBatchFoundationRoute from '@/router/foundation/periodBatch/periodBatch'
import applicantFoundationRoute from '@/router/foundation/applicant/applicant'
import typeReceptionFoundationRoute from '@/router/foundation/typeReception/typeReception'
import setupCmsPage from '@/router/admin/setup/cms/page'
import billFoundationRoute from '@/router/foundation/bill/bill'
import setupRolePermission from '@/router/admin/setup/user-role'
import applicantProgressHistoryRoute from '@/router/user/progressHistory/progressHistory'
import userManagementRoute from '@/router/admin/setup/user_management'
import studentRoute from '@/router/admin/student/student'
import periodBatchRoute from '@/router/admin/setup/periodBatch'
import exportRoute from '@/router/admin/management/export/export'
import importRoute from '@/router/admin/management/import/import'
import notificationRoute from '@/router/admin/setup/notification/notification'
import myReferralRoute from '@/router/admin/referral/referral'
import configurationRoute from '@/router/admin/setup/configuration/configuration'
import transactionRoute from '@/router/admin/transaction/bill'
import applicantSystemAdminRoute from '@/router/sys_admin/applicant/applicant'
import userSystemAdminRoute from '@/router/sys_admin/user/user'
import institutionSystemAdminRoute from '@/router/sys_admin/institution/institution'
import rolesSystemAdminRoute from '@/router/sys_admin/roles/roles'
import failedMessageSystemAdminRoute from '@/router/sys_admin/failed_message/failed_message'
import customMessageRoute from '@/router/admin/custom_message/custom_message'
import invocationSystemAdminRoute from '@/router/sys_admin/invocation/invocation'
import applicantFeedbackRoute from '@/router/user/feedback/feedback'
import commonNotificationRoute from '@/router/admin/notification/notification'
import inboundSystemAdminRoute from '@/router/sys_admin/inbound/inbound'
import adminFeedbackRoute from '@/router/admin/feedback/feedback'
// import setupCmsMenu from "@/router/admin/setup/cms/menu";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/landing',
    component: () => import('@/layouts/main-layout/MainLayout.vue'),
    meta: {
      middleware: 'auth',
    },
    children: [
      ...dashboardRoute,
      ...applicantRoute,
      ...registrationNumberRoute,
      ...progressRoute,
      ...entranceRoute,
      ...referenceRoute,
      ...classProgramRoute,
      ...departmentRoute,
      ...testLocationRoute,
      ...documentRoute,
      ...institutionClassesRoute,
      ...periodRoute,
      ...packageCostRoute,
      ...biodataRoute,
      ...cmsRoute,
      ...typeReceptionRoute,
      ...billRoute,
      ...applicantBillRoute,
      ...applicantDocumentRoute,
      ...applicantFeedbackRoute,
      ...announcementAdminRoute,
      ...progressFoundationRoute,
      ...institutionFoundationRoute,
      ...periodBatchFoundationRoute,
      ...applicantFoundationRoute,
      ...typeReceptionFoundationRoute,
      ...announcementAdminRoute,
      ...setupCmsPage,
      // ...setupCmsMenu,
      ...setupRolePermission,
      ...billFoundationRoute,
      ...applicantProgressHistoryRoute,
      ...userManagementRoute,
      ...studentRoute,
      ...periodBatchRoute,
      ...exportRoute,
      ...importRoute,
      ...notificationRoute,
      ...myReferralRoute,
      ...configurationRoute,
      ...transactionRoute,
      ...commonNotificationRoute,
      ...adminFeedbackRoute,

      //   System Admin Route
      ...applicantSystemAdminRoute,
      ...userSystemAdminRoute,
      ...institutionSystemAdminRoute,
      ...rolesSystemAdminRoute,
      ...failedMessageSystemAdminRoute,
      ...customMessageRoute,
      ...invocationSystemAdminRoute,
      ...inboundSystemAdminRoute,
    ],
  },
  {
    path: '/landing',
    redirect: '/home',
  },
  {
    path: '/',
    component: () => import('@/layouts/landing-layout/BaseLayout.vue'),
    name: 'base-landing-layout',
    meta: {
      setTitle: false,
    },
    children: [
      {
        path: '/home',
        name: 'landing-home',
        component: () => import('@/views/landing/HomeWrapper.vue'),
        meta: {
          pageTitle: 'Home',
        },
      },
      // {
      //     path: "/contact-us",
      //     name: "landing-contact-us",
      //     component: () => import("@/views/landing/ContactUs.vue"),
      //     meta: {
      //         pageTitle: "Contact Us",
      //     },
      // },
      // {
      //     path: "/profile",
      //     name: "landing-profile",
      //     component: () => import("@/views/landing/Profile.vue"),
      //     meta: {
      //         pageTitle: "Profile",
      //     },
      // },
      // {
      //     path: "/:slug",
      //     name: "landing-dynamic-page",
      //     component: () => import("@/views/landing/Empty.vue"),
      //     meta: {
      //         pageTitle: "Landing Page",
      //     },
      // },
      {
        path: '/sign-in',
        name: 'apps-login',
        component: () => import('@/views/apps/auth/login/Login.vue'),
        meta: {
          pageTitle: 'signIn',
        },
      },
      {
        path: '/register',
        name: 'apps-register',
        component: () => import('@/views/apps/auth/register/RegisterBuffer.vue'),
        meta: {
          pageTitle: 'register',
        },
      },
      // {
      //     path: "/article/:slug",
      //     name: "landing-detail-article",
      //     component: () => import("@/views/landing/DetailArticle.vue"),
      //     meta: {
      //         pageTitle: "Detail Artikel",
      //     },
      // },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/EmptyLayout.vue'),
    children: [...appsRegisterRoute, ...appsLoginRoute, ...appsResetRoute],
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/sign-in-system-admin',
        name: 'sign-in-system-admin',
        component: () => import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
        meta: {
          pageTitle: 'signIn',
        },
      },
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/apps/auth/login/Login.vue'),
        meta: {
          pageTitle: 'signIn',
        },
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
        meta: {
          pageTitle: 'Sign Up',
        },
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
        meta: {
          pageTitle: 'passwordReset',
        },
      },
    ],
  },
  {
    path: '/multi-step-sign-up',
    name: 'multi-step-sign-up',
    component: () => import('@/views/crafted/authentication/MultiStepSignUp.vue'),
    meta: {
      pageTitle: 'Multi-step Sign up',
    },
  },
  ...systemRoute,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  const configStore = useConfigStore()
  const menuStore = useMenuStore()

  const { t } = i18n.global
  // current page view title

  if (to.meta.setTitle !== false) {
    document.title = `${t(('menu.' + to.meta.pageTitle) as string)} | ${
      import.meta.env.VITE_APP_NAME
    }`
  }

  // reset config to initial state
  configStore.resetLayoutConfig()

  // verify auth token before each page change
  authStore.verifyAuth()

  // before page access check if page requires authentication
  // if (to.meta.middleware == "auth") {
  //   if (authStore.isAuthenticated) {
  //     next();
  //   } else {
  //     next({ name: "sign-in" });
  //   }
  // } else {
  //   next();
  // }
  //
  // if (to.meta.middleware === 'auth') {
  //     if (
  //         menuStore.availableRoute &&
  //         !menuStore.availableRoute.some((route) => to.path.startsWith(route)) &&
  //         to.path !== '/dashboard' &&
  //         to.path !== '/profile'
  //         // to.path !== '/app/finance/bill/detail' &&
  //         // to.path !== '/app/finance/transaction/detail' &&
  //     ) {
  //         return next({ name: '403' })
  //     }
  //
  //     if (!authStore.isAuthenticated) {
  //         return next({ name: 'sign-in' })
  //     }
  // }

  next()

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
})

export default router
