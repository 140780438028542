import { defineStore } from 'pinia'

import { loadingBlock, loadingUnBlock, requestFailed } from '@/core/helpers/helpers'
import ApiService from '@/core/services/ApiService'

interface IMenuItem {
    id: string
    label: string
    slug: string
    icon: string | null
    route: string
    url: string
    level: number
    order: number
    parent_id: string | null
    created_at: string
    updated_at: string
    deleted_at: string | null
    children: IMenuItem[]
}

export const useMenuStore = defineStore('menu', {
    state: () => ({
        sidebarMenu: [] as IMenuItem[],
        availableRoute: [ '/dashboard' ] as string[],
        // availableRoute: [] as string[],
        isLoading: true,
    }),
    persist: true,
    getters: {},
    actions: {
        async getProfileMenus() {
            try {
                this.isLoading = true
                loadingBlock()

                const req = await ApiService.get(`menus/permission`)

                const menus = req.data.payload
                this.sidebarMenu = menus.data[0].children ?? []

                this.isLoading = false
                loadingUnBlock()
            } catch (e: any) {
                this.isLoading = false
                loadingUnBlock()
                requestFailed(e)
                throw new Error(e)
            }
        },

        extractRoutesFromMenuItem(menuItems: IMenuItem[]): string[] {
            let routes: string[] = []
            for (const item of menuItems) {
                if (item.url !== '#') {
                    routes.push(item.url)
                }
                if (item.children && item.children.length > 0) {
                    routes = routes.concat(this.extractRoutesFromMenuItem(item.children))
                }
            }
            return routes
        },

        setRoute(menuResponse: IMenuItem[]) {
            this.availableRoute = this.extractRoutesFromMenuItem(menuResponse)
        },
    },
})
