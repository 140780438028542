import type { RouteRecordRaw } from "vue-router";

const userSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/user",
    name: "system-admin-user",
    component: () => import("@/views/sys_admin/user/UserList.vue"),
    meta: {
      pageTitle: "user",
      breadcrumbs: ['systemAdmin', 'user'],
    },
  },
];

export default userSystemAdminRoute;
