import type { RouteRecordRaw} from "vue-router";

const appsResetRoute: Array<RouteRecordRaw> = [
  {
    path: "/reset",
    name: "apps-reset",
    component: () => import("@/views/apps/auth/forgot-password/ForgotPassword.vue"),
    meta: {
      pageTitle: "resetPassword",
    },
  },
  {
    path: "/reset",
    name: "apps-reset-email",
    component: () => import("@/views/apps/auth/forgot-password/components/ForgotPasswordEmail.vue"),
    meta: {
      pageTitle: "resetPassword",
    },
  },
  {
    path: "/reset",
    name: "apps-reset-number",
    component: () => import("@/views/apps/auth/forgot-password/components/ForgotPasswordNumber.vue"),
    meta: {
      pageTitle: "resetPassword",
    },
  },
];

export default appsResetRoute;