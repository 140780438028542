import type { RouteRecordRaw } from "vue-router";

const rolesSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/roles",
    name: "system-admin-roles",
    component: () => import("@/views/sys_admin/roles/RolesList.vue"),
    meta: {
      pageTitle: "userRole",
      breadcrumbs: ['systemAdmin', 'userRole'],
    },
  },
];

export default rolesSystemAdminRoute;
