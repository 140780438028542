import type { RouteRecordRaw } from "vue-router";

const documentRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/document",
        name: "admin-master-document",
        component: () => import("@/views/admin/master/document/DocumentList.vue"),
        meta: {
            pageTitle: "requiredDocument",
            breadcrumbs: ["masterData", "requiredDocument"],
        },
    },
    {
        path: "/admin/master/document/create",
        name: "admin-master-document-create",
        component: () => import("@/views/admin/master/document/DocumentCreate.vue"),
        meta: {
            pageTitle: "requiredDocument",
            breadcrumbs: ["masterData", "requiredDocument", "create"],
        }
    },
    {
        path: "/admin/master/document/update",
        name: "admin-master-document-update",
        component: () => import("@/views/admin/master/document/DocumentUpdate.vue"),
        meta: {
            pageTitle: "requiredDocument",
            breadcrumbs: ["masterData", "requiredDocument", "update"],
        }
    },
    {
        path: "/admin/master/document/update-file",
        name: "admin-master-document-update-file",
        component: () => import("@/views/admin/master/document/DocumentUpdateFile.vue"),
        meta: {
            pageTitle: "requiredDocument",
            breadcrumbs: ["masterData", "requiredDocument", "update"],
        }
    },
];

export default documentRoute;
