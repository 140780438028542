import type { RouteRecordRaw } from "vue-router";

const periodBatchRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/setup/period-batch",
        name: "admin-setup-period-batch",
        component: () => import("@/views/admin/setup/period/period_batches/PeriodBatchesList.vue"),
        meta: {
            pageTitle: "periodBatch",
            breadcrumbs: ["setupPPDB", "period", "batch"],
        }
    },
    {
        path: "/admin/setup/period-batch/create",
        name: "admin-setup-period-batches-create",
        component: () => import("@/views/admin/setup/period/period_batches/PeriodBatchesCreate.vue"),
        meta: {
            pageTitle: "periodBatch",
            breadcrumbs: ["setupPPDB", "period", "batch", "create"],
        }
    },
    {
        path: "/admin/setup/period-batch/attach",
        name: "admin-setup-period-batch-attach",
        component: () => import("@/views/admin/setup/period/period_batches/PeriodBatchAttachEntranceToClassProgram.vue"),
        meta: {
            pageTitle: "periodBatch",
            breadcrumbs: ["setupPPDB", "period", "batch", "entrance"],
        }
    },
    {
        path: "/admin/setup/period-batch/progress",
        name: "admin-setup-period-batch-progress",
        component: () => import("@/views/admin/setup/period/period_batches/PeriodBatchProgressTypeReception.vue"),
        meta: {
            pageTitle: "periodBatch",
            breadcrumbs: ["setupPPDB", "period", "batch", "progress"],
        }
    },
    {
        path: "/admin/setup/period-batch/combination",
        name: "admin-setup-period-batch-combination",
        component: () => import("@/views/admin/setup/period/period_batches/PeriodBatchesCombinationCreate.vue"),
        meta: {
            pageTitle: "periodBatch",
            breadcrumbs: ["setupPPDB", "period", "batch", "combination"],
        }
    },
];

export default periodBatchRoute;
