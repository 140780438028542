import type { RouteRecordRaw } from "vue-router";

const billFoundationRoute: Array<RouteRecordRaw> = [
    {
        path: "/foundation/bill",
        name: "foundation-bill",
        component: () => import("@/views/foundation/bill/BillList.vue"),
        meta: {
            pageTitle: "bill",
            breadcrumbs: ['foundation', 'bill'],
        },
    },
];

export default billFoundationRoute;
