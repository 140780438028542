import type { RouteRecordRaw } from "vue-router";

const systemRoute: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/system/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/403",
        name: "403",
        component: () => import("@/views/system/Error403.vue"),
        meta: {
          pageTitle: "Error 403",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/system/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
      {
        path: "/buffer-zone",
        name: "217",
        component: () => import("@/views/system/BufferZone.vue"),
        meta: {
          pageTitle: "bufferZone",
        },
      },
    ],
  },
];

export default systemRoute;
