import type { RouteRecordRaw } from "vue-router";

const customMessageRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/setup/custom-message",
        name: "custom-message",
        component: () => import("@/views/admin/custom_message/CustomMessageList.vue"),
        meta: {
            pageTitle: "customMessage",
            breadcrumbs: ["customMessage"],
        },
    },
];

export default customMessageRoute;
