import type { RouteRecordRaw } from "vue-router";

const typeReceptionRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/type-reception",
        name: "admin-master-typeReception",
        component: () => import("@/views/admin/master/typeReception/TypeReceptionList.vue"),
        meta: {
            pageTitle: "typeReception",
            breadcrumbs: ["masterData", "typeReception"],
        },
    },
    {
        path: "/admin/master/type-reception/create",
        name: "admin-master-typeReception-create",
        component: () => import("@/views/admin/master/typeReception/TypeReceptionCreate.vue"),
        meta: {
            pageTitle: "typeReception",
            breadcrumbs: ["masterData", "typeReception", "create"]
        },
    },
    {
        path: "/admin/master/type-reception/update",
        name: "admin-master-typeReception-update",
        component: () => import("@/views/admin/master/typeReception/TypeReceptionUpdate.vue"),
        meta: {
            pageTitle: "typeReception",
            breadcrumbs: ["masterData", "typeReception", "update"]
        },
    },
];

export default typeReceptionRoute;
