import type { RouteRecordRaw } from "vue-router";

const commonNotificationRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/notification",
    name: "notification",
    component: () => import("@/views/notification/NotificationList.vue"),
    meta: {
      pageTitle: "notification",
      breadcrumbs: ["notification"],
    },
  },
  {
    path: "/admin/notification/view",
    name: "notification-view",
    component: () => import("@/views/notification/NotificationView.vue"),
    meta: {
      pageTitle: "notification",
      pageType: 'view',
      breadcrumbs: ["notification"],
    },
  },
];

export default commonNotificationRoute;
