import type { RouteRecordRaw } from "vue-router";

const institutionClassesRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/institution-classes",
        name: "admin-master-institution-classes",
        component: () => import("@/views/admin/master/institutionClasses/InstitutionClassesList.vue"),
        meta: {
            pageTitle: "institutionClasses",
            breadcrumbs: ["masterData", "institutionClasses"],
        },
    },
    // {
    //     path: "/admin/master/institution-classes/create",
    //     name: "admin-master-institution-create",
    //     component: () => import("@/views/admin/master/institutionClasses/InstitutionClassesCreate.vue"),
    //     meta: {
    //         pageTitle: "institutionClasses",
    //         breadcrumbs: ["masterData", "institutionClasses", "create"],
    //     }
    // },
    // {
    //     path: "/admin/master/institution-classes/update",
    //     name: "admin-master-institution-update",
    //     component: () => import("@/views/admin/master/institutionClasses/InstitutionClassesUpdate.vue"),
    //     meta: {
    //         pageTitle: "institutionClasses",
    //         breadcrumbs: ["masterData", "institutionClasses", "update"],
    //     }
    // },
];

export default institutionClassesRoute;
