import type { RouteRecordRaw } from "vue-router";

const myReferralRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/my-referral",
    name: "referral",
    component: () => import("@/views/admin/referral/ReferralList.vue"),
    meta: {
      pageTitle: "referral",
      breadcrumbs: ["referral"],
    },
  },
];

export default myReferralRoute;
