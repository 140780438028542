import type { RouteRecordRaw } from "vue-router";

const departmentRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/department",
        name: "admin-master-department",
        component: () => import("@/views/admin/master/department/DepartmentList.vue"),
        meta: {
            pageTitle: "department",
            breadcrumbs: ["masterData", "department"],
        },
    },
    // {
    //     path: "/admin/master/department/create",
    //     name: "admin-master-department-create",
    //     component: () => import("@/views/admin/master/department/DepartmentCreate.vue"),
    //     meta: {
    //         pageTitle: "department",
    //         breadcrumbs: ["masterData", "department", "create"]
    //     }
    // },
    // {
    //     path: "/admin/master/department/update",
    //     name: "admin-master-department-update",
    //     component: () => import("@/views/admin/master/department/DepartmentUpdate.vue"),
    //     meta: {
    //         pageTitle: "department",
    //         breadcrumbs: ["masterData", "department", "update"]
    //     }
    // },
];

export default departmentRoute;
