import type { RouteRecordRaw } from "vue-router";

const transactionRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/transaction",
        name: "transaction",
        component: () => import("@/views/admin/transaction/TransactionList.vue"),
        meta: {
            pageTitle: "transaction",
            breadcrumbs: ["transaction"],
        },
    },
];

export default transactionRoute;
