import type { RouteRecordRaw } from 'vue-router'

const setupRolePermission: Array<RouteRecordRaw> = [
  {
    path: "/admin/setup/role-permission",
    name: "role_permission",
    component: () => import("@/views/admin/setup/roles/Role.vue"),
    meta: {
      pageTitle: "rolePermission",
      breadcrumbs: ["setup", "rolePermission"],
    },
  },
]

export default setupRolePermission
