import type { RouteRecordRaw } from "vue-router";

const userManagementRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/setup/user-management",
        name: "admin-user-management",
        component: () => import("@/views/admin/setup/userManagement/UserManagementList.vue"),
        meta: {
            pageTitle: "userManagement",
            breadcrumbs: ["setup", "userManagement"],
        },
    },
    {
        path: "/admin/setup/user-management/sync",
        name: "admin-user-management-sync",
        component: () => import("@/views/admin/setup/userManagement/UserManagementSync.vue"),
        meta: {
            pageTitle: "userManagement",
            breadcrumbs: ["setup", "userManagement", "sync"],
        },
    },
];

export default userManagementRoute;
