import type { RouteRecordRaw } from "vue-router";

const applicantFoundationRoute: Array<RouteRecordRaw> = [
    {
        path: "/foundation/applicant",
        name: "foundation-applicant",
        component: () => import("@/views/foundation/applicant/ApplicantList.vue"),
        meta: {
            pageTitle: "applicant",
            breadcrumbs: ['foundation', 'applicant'],
        },
    },
    {
        path: "/foundation/applicant/detail",
        name: "foundation-applicant-detail",
        component: () => import("@/views/foundation/applicant/ApplicantDetail.vue"),
        meta: {
            pageTitle: "applicant",
            breadcrumbs: ['foundation', 'applicant', 'detail'],
        },
    },
];

export default applicantFoundationRoute;
