import type { RouteRecordRaw } from "vue-router";

const progressRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/setup/period/batches/progress",
    name: "admin-setup-period-batches-progress",
    component: () => import("@/views/admin/setup/period/period_batches/progress/ProgressList.vue"),
    meta: {
      pageTitle: "progress",
      breadcrumbs: ["setupPPDB", "period", "batches", "progress"],
    },
  },
  {
    path: "/admin/setup/period/batches/progress/create",
    name: "admin-setup-period-batches-progress-create",
    component: () => import("@/views/admin/setup/period/period_batches/progress/ProgressCreate.vue"),
    meta: {
      pageTitle: "progress",
      breadcrumbs: ["setupPPDB", "period", "batches", "progress"],
    },
  },
  {
    path: "/admin/setup/period/batches/progress/update",
    name: "admin-setup-period-batches-progress-update",
    component: () => import("@/views/admin/setup/period/period_batches/progress/ProgressUpdate.vue"),
    meta: {
      pageTitle: "progress",
      breadcrumbs: ["setupPPDB", "period", "batches", "progress"],
    },
  },
  {
    path: "/admin/setup/period/batches/progress/attach",
    name: "admin-setup-period-batches-progress-attach",
    component: () => import("@/views/admin/setup/period/period_batches/progress/progress_attach/ProgressAttach.vue"),
    meta: {
      pageTitle: "progress",
      breadcrumbs: ["setupPPDB", "period", "batches", "progress", "attach"],
    },
  },
];

export default progressRoute;
