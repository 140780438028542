import type { RouteRecordRaw } from 'vue-router'

const applicantFeedbackRoute: Array<RouteRecordRaw> = [
  {
    path: '/user/feedback',
    name: 'user-feedback',
    component: () => import('@/views/user/feedback/FeedbackList.vue'),
    meta: {
      pageTitle: 'feedback',
    },
  },
  {
    path: '/user/feedback/create',
    name: 'user-feedback-create',
    component: () => import('@/views/user/feedback/FeedbackCreate.vue'),
    meta: {
      pageTitle: 'feedback',
      breadcrumbs: ['feedback', 'create'],
    },
  },
]

export default applicantFeedbackRoute
