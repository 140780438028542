import type { RouteRecordRaw } from "vue-router";

const applicantDocumentRoute: Array<RouteRecordRaw> = [
    {
        path: "/user/document",
        name: "user-document",
        component: () => import("@/views/user/document/DocumentList.vue"),
        meta: {
            pageTitle: "document",
        },
    },
    {
        path: "/user/document-upload",
        name: "user-document-upload",
        component: () => import("@/views/user/document/DocumentCreate.vue"),
        meta: {
            pageTitle: "document",
            breadcrumbs: ['document', 'create'],
        },
    },

];

export default applicantDocumentRoute;
