import type { RouteRecordRaw } from "vue-router";

const applicantProgressHistoryRoute: Array<RouteRecordRaw> = [
    {
        path: "/user/progress-history",
        name: "progress-history",
        component: () => import("@/views/user/progressHistory/ProgressHistoryList.vue"),
        meta: {
            pageTitle: "progressHistory",
            breadcrumbs: ['history'],
        },
    },

];

export default applicantProgressHistoryRoute;
