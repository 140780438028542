import type { RouteRecordRaw } from "vue-router";

const invocationSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/invocation",
    name: "system-admin-invocation",
    component: () => import("@/views/sys_admin/invocation/InvocationList.vue"),
    meta: {
      pageTitle: "invocation",
      breadcrumbs: ['systemAdmin', 'invocation'],
    },
  },
];

export default invocationSystemAdminRoute;
