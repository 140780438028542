import type { RouteRecordRaw } from 'vue-router'

const adminFeedbackRoute: Array<RouteRecordRaw> = [
  {
    path: '/admin/feedback',
    name: 'admin-feedback',
    component: () => import('@/views/admin/feedback/FeedbackList.vue'),
    meta: {
      pageTitle: 'feedback',
      breadcrumbs: ["feedback"],
    },
  },
]

export default adminFeedbackRoute
