import type { RouteRecordRaw } from "vue-router";

const announcementAdminRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/announcement",
        name: "admin-announcement",
        component: () => import("@/views/admin/announcement/AnnouncementList.vue"),
        meta: {
            pageTitle: "announcement",
            breadcrumbs: ["announcement"],
        },
    },
    {
        path: "/admin/add-announcement",
        name: "admin-add-announcement",
        component: () => import("@/views/admin/announcement/AnnouncementCreate.vue"),
        meta: {
            pageTitle: "announcement",
            breadcrumbs: ["announcement", "create"],
        }
    },
    {
        path: "/admin/update-announcement",
        name: "admin-update-announcement",
        component: () => import("@/views/admin/announcement/AnnouncementUpdate.vue"),
        meta: {
            pageTitle: "announcement",
            breadcrumbs: ["announcement", "update"],
        }
    },
    {
        path: "/admin/detail-announcement",
        name: "admin-detail-announcement",
        component: () => import("@/views/admin/announcement/AnnouncementDetail.vue"),
        meta: {
            pageTitle: "announcement",
            breadcrumbs: ["announcement", "detail"],
        }
    }
];

export default announcementAdminRoute;
