import type { RouteRecordRaw } from "vue-router";

const periodBatchFoundationRoute: Array<RouteRecordRaw> = [
    {
        path: "/foundation/period-batch",
        name: "foundation-periodBatch",
        component: () => import("@/views/foundation/periodBatch/PeriodBatchList.vue"),
        meta: {
            pageTitle: "periodBatch",
            breadcrumbs: ['foundation', 'periodBatch'],
        },
    },
    {
        path: "/foundation/period-batch/detail",
        name: "foundation-periodBatch-detail",
        component: () => import("@/views/foundation/periodBatch/PeriodBatchDetail.vue"),
        meta: {
            pageTitle: "periodBatch",
            breadcrumbs: ['foundation', 'periodBatch', 'detail'],
        },
    },
];

export default periodBatchFoundationRoute;
