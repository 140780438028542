import type { RouteRecordRaw } from 'vue-router'

const biodataRoute: Array<RouteRecordRaw> = [
    {
        path: '/user/biodata',
        name: 'biodata',
        component: () => import('@/views/user/biodata/BiodataForm.vue'),
        meta: {
            pageTitle: 'biodata',
            breadcrumbs: ['biodata'],
        },
    },
]

export default biodataRoute

