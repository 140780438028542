import { defineStore } from 'pinia'
import { loadingBlock, loadingUnBlock, requestFailed } from '@/core/helpers/helpers'
import ApiService from '@/core/services/ApiService'

interface IConfigItem {
  key: string
  value: string
}

export const useConfigRegisterStore = defineStore('register', {
  state: () => ({
    configRegister: [] as IConfigItem[],
    isLoading: true,
  }),
  getters: {
    getConfigByKey: (state) => (key: string) => {
      return state.configRegister.find(config => config.key === key)?.value
    },
  },
  actions: {
    async getAllConfiguration() {
      try {
        this.isLoading = true
        loadingBlock()

        const req = await ApiService.get(`institution/management/configurations`, undefined, "public")

        const data = req.data.payload.data

        this.configRegister = data.map((item: { key: string, value: string }) => ({
          key: item.key,
          value: item.value,
        }))

        localStorage.setItem('configRegister', JSON.stringify(this.configRegister))

        this.isLoading = false
        loadingUnBlock()
      } catch (e: any) {
        this.isLoading = false
        loadingUnBlock()
        requestFailed(e)
        throw new Error(e)
      }
    },

    loadFromLocalStorage() {
      const storedConfig = localStorage.getItem('configRegister')
      if (storedConfig) {
        this.configRegister = JSON.parse(storedConfig)
      }
    },

    loadFromLocalStorageByKey(key: string): string | undefined {
      const storedConfig = localStorage.getItem('configRegister')
      if (storedConfig) {
        const configRegister = JSON.parse(storedConfig) as IConfigItem[]
        // Cari item berdasarkan key
        const configItem = configRegister.find(item => item.key === key)
        return configItem ? configItem.value : undefined
      }
      return undefined
    },
  },
})
