import type { RouteRecordRaw } from "vue-router";

const applicantBillRoute: Array<RouteRecordRaw> = [
    {
        path: "/user/bill",
        name: "user-bill",
        component: () => import("@/views/user/bill/BillList.vue"),
        meta: {
            pageTitle: "bill",
        },
    },
];

export default applicantBillRoute;
