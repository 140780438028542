import type { RouteRecordRaw } from 'vue-router'

const cmsRoute: Array<RouteRecordRaw> = [
    {
        path: '/cms/articles',
        name: 'cms-articles',
        component: () => import('@/views/admin/cms/articles/ArticlesList.vue'),
        meta: {
            pageTitle: 'articles',
            breadcrumbs: ['articles'],
        },
    },
    {
        path: '/cms/articles/create',
        name: 'cms-articles-create',
        component: () => import('@/views/admin/cms/articles/ArticlesCreate.vue'),
        meta: {
            pageTitle: 'articles',
            breadcrumbs: ['articles', 'create'],
        },
    },
    {
        path: '/cms/articles/update',
        name: 'cms-articles-update',
        component: () => import('@/views/admin/cms/articles/ArticlesUpdate.vue'),
        meta: {
            pageTitle: 'articles',
            breadcrumbs: ['articles', 'update'],
        },
    },
    {
        path: '/cms/categories',
        name: 'cms-categories',
        component: () => import('@/views/admin/cms/categories/CategoriesList.vue'),
        meta: {
            pageTitle: 'categories',
            breadcrumbs: ['categories'],
        },
    },
    {
        path: '/cms/categories/create',
        name: 'cms-categories-create',
        component: () => import('@/views/admin/cms/categories/CategoriesCreate.vue'),
        meta: {
            pageTitle: 'categories',
            breadcrumbs: ['categories', 'create'],
        },
    },
    {
        path: '/cms/categories/update',
        name: 'cms-categories-update',
        component: () => import('@/views/admin/cms/categories/CategoriesUpdate.vue'),
        meta: {
            pageTitle: 'categories',
            breadcrumbs: ['categories', 'update'],
        },
    },
    {
        path: '/cms/tags',
        name: 'cms-tags',
        component: () => import('@/views/admin/cms/tags/TagsList.vue'),
        meta: {
            pageTitle: 'tags',
            breadcrumbs: ['tags'],
        },
    },
    {
        path: '/cms/tags/create',
        name: 'cms-tags-create',
        component: () => import('@/views/admin/cms/tags/TagsCreate.vue'),
        meta: {
            pageTitle: 'tags',
            breadcrumbs: ['tags', 'create'],
        },
    },
    {
        path: '/cms/tags/update',
        name: 'cms-tags-update',
        component: () => import('@/views/admin/cms/tags/TagsUpdate.vue'),
        meta: {
            pageTitle: 'tags',
            breadcrumbs: ['tags', 'update'],
        },
    },

    {
        path: '/cms/message',
        name: 'cms-message',
        component: () => import('@/views/admin/cms/messages/MessageList.vue'),
        meta: {
            pageTitle: 'message',
            breadcrumbs: ['message'],
        },
    },
]

export default cmsRoute
