import type { RouteRecordRaw } from "vue-router";

const testLocationRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/test-location",
        name: "admin-master-testLocation",
        component: () => import("@/views/admin/master/testLocation/TestLocationList.vue"),
        meta: {
            pageTitle: "testLocation",
            breadcrumbs: ["masterData", "testLocation"],
        },
    },
    {
        path: "/admin/master/test-location/create",
        name: "admin-master-testLocation-create",
        component: () => import("@/views/admin/master/testLocation/TestLocationCreate.vue"),
        meta: {
            pageTitle: "testLocation",
            breadcrumbs: ["masterData", "testLocation", "create"],
        },
    },
    {
        path: "/admin/master/test-location/update",
        name: "admin-master-testLocation-update",
        component: () => import("@/views/admin/master/testLocation/TestLocationUpdate.vue"),
        meta: {
            pageTitle: "testLocation",
            breadcrumbs: ["masterData", "testLocation", "update"],
        },
    },
];

export default testLocationRoute;
