import type { RouteRecordRaw } from "vue-router";

const referenceRoute: Array<RouteRecordRaw> = [
  {
    path: "/admin/master/referral",
    name: "admin-master-reference",
    component: () => import("@/views/admin/master/reference/ReferenceList.vue"),
    meta: {
      pageTitle: "referral",
      breadcrumbs: ["masterData", "referral"],
    },
  },
  {
    path: "/admin/master/referral/create",
    name: "admin-master-reference-create",
    component: () =>
      import("@/views/admin/master/reference/ReferenceCreate.vue"),
    meta: {
      pageTitle: "referral",
      breadcrumbs: ["masterData", "referral", "create"],
    },
  },
  {
    path: "/admin/master/referral/update",
    name: "admin-master-reference-update",
    component: () =>
      import("@/views/admin/master/reference/ReferenceUpdate.vue"),
    meta: {
      pageTitle: "referral",
      breadcrumbs: ["masterData", "referral", "update"],
    },
  },
  {
    path: "/admin/master/referral/detail",
    name: "admin-master-reference-detail",
    component: () =>
      import("@/views/admin/master/reference/ReferenceDetail.vue"),
    meta: {
      pageTitle: "referral",
      breadcrumbs: ["masterData", "referral", "detail"],
    },
  },
];

export default referenceRoute;
