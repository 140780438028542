import type { RouteRecordRaw } from "vue-router";

const applicantSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/applicant",
    name: "system-admin-applicant",
    component: () => import("@/views/sys_admin/applicant/ApplicantList.vue"),
    meta: {
      pageTitle: "applicant",
      breadcrumbs: ['systemAdmin', 'applicant'],
    },
  },
  {
    path: "/system-admin/applicant/detail",
    name: "system-admin-applicant-detail",
    component: () => import("@/views/sys_admin/applicant/ApplicantDetail.vue"),
    meta: {
      pageTitle: "applicant",
      breadcrumbs: ['systemAdmin', 'applicant', 'detail'],
    },
  },
];

export default applicantSystemAdminRoute;
