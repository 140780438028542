import type { RouteRecordRaw } from "vue-router";

const institutionFoundationRoute: Array<RouteRecordRaw> = [
    {
        path: "/foundation/institution",
        name: "foundation-institution",
        component: () => import("@/views/foundation/institution/InstitutionList.vue"),
        meta: {
            pageTitle: "institution",
            breadcrumbs: ['foundation', 'institution'],
        },
    },
    {
        path: "/foundation/institution/detail",
        name: "foundation-institution-detail",
        component: () => import("@/views/foundation/institution/InstitutionDetail.vue"),
        meta: {
            pageTitle: "institution",
            breadcrumbs: ['foundation', 'institution', 'detail'],
        },
    },
];

export default institutionFoundationRoute;
