import type { RouteRecordRaw } from "vue-router";

const studentRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/student",
        name: "student",
        component: () => import("@/views/admin/student/StudentList.vue"),
        meta: {
            pageTitle: "student",
            breadcrumbs: ["student"],
        },
    },
];

export default studentRoute;
