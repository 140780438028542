import type { RouteRecordRaw } from "vue-router";

const dashboardRoute: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      pageTitle: "dashboard",
      breadcrumbs: ["dashboards"],
    },
  },
];

export default dashboardRoute;
