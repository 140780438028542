import moment from "moment";
import { ElLoading, ElNotification } from "element-plus";
import type { AxiosResponse } from "axios";
import i18n from '@/core/plugins/i18n'
import axios from 'axios';
import JwtService from '@/core/services/JwtService';

const { t, te } = i18n.global

export function formattedDate(date: any) {
  /* ex: 30 June 2023 */
  return moment(date).format("DD MMMM YYYY");
}

export function formattedDate2(date: any) {
  /* ex: 30-07-2023 */
  return moment(date).format("YYYY-MM-DD");
}

export function formattedDate3(date: any) {
  /* ex: 07-2023 */
  return moment(date).format('MM YYYY')
}

export function formattedDate4(date: any) {
/* ex: 30 JuNe 2023 12:00:00 */
  return moment(date).format("DD MMMM YYYY HH:mm:ss");
}

export function loadingBlock() {
  return ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
}

export function loadingUnBlock() {
  setTimeout(() => {
    ElLoading.service().close();
  }, 300);
}

export function requestSuccess(req: AxiosResponse<any, any>) {
  setTimeout(() => {
    ElNotification({
      title: req.data.rc,
      message: req.data.message,
      type: "success",
    });
  }, 300);
}

export function requestFailed(error: any) {
  setTimeout(() => {
    ElNotification({
      title: error.response.data.rc,
      message: error.response.data.message,
      type: "error",
    });
  }, 300);
}

export function isConfigurationText(isActive: boolean = false): string {
  return isActive ? t("generalLang.configure") : t("generalLang.notConfigured");
}

export function isActiveText(isActive: boolean = false): string {
  return isActive ? t("generalLang.active") : t("generalLang.inactive");
}

export function genderText(gender: string | null){
  if (gender === null) {
    return t("generalLang.male") + " & " + t("generalLang.female");
  } else if (gender === "male") {
    return t("generalLang.male");
  } else if (gender === "female") {
    return t("generalLang.female");
  } else {
    return "";
  }
}


export function idSplitter(params: string): string {
  return params.toUpperCase().split("-")[0];
}

export function orderFormat(params: string | null): string {
  if (params == 'ascending' || params == null) {
    return 'asc'
  } else {
    return 'desc'
  }
}

export function toTitleCase(params: string) {
  return params.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase()
  })
}

export const numberFormat = (
    number: string | number,
    decimals = 0,
    decPoint = ',',
    thousandSep = '.',
) => {
  // Strip all characters but numerical ones.
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousandSep === 'undefined' ? ',' : thousandSep
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s: string[] = ['']

  const toFixedFix = (n, prec) => {
    const k = 10 ** prec
    return '' + Math.round(n * k) / k
  }
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  return s.join(dec)
}

export const formatRp = (number: number, withRp: boolean = true) => {
  return (withRp ? 'Rp. ' : '') + numberFormat(number)
}

export const isEmailValid = (rule: any, value: any, callback: any) => {
  if (value == '') {
    callback(new Error('email is required'))
  } else {
    // eslint-disable-next-line no-useless-escape
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      callback(new Error('email is not valid'))
    }
  }
  callback()
}

export const NumbersOnly = (char: any) => {
  char = char ? char : window.event
  const charCode = char.which ? char.which : char.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) && charCode !== 46) {
    char.preventDefault()
  } else {
    return true
  }
}

export const SpellingMoney = (number) => {
  const terbilang1to19 = [
    '',
    'satu',
    'dua',
    'tiga',
    'empat',
    'lima',
    'enam',
    'tujuh',
    'delapan',
    'sembilan',
    'sepuluh',
    'sebelas',
    'dua belas',
    'tiga belas',
    'empat belas',
    'lima belas',
    'enam belas',
    'tujuh belas',
    'delapan belas',
    'sembilan belas',
  ]

  const terbilangRibuan = ['', 'ribu', 'juta', 'miliar', 'triliun']

  function terbilangSatuan(ratusan) {
    let terbilang = ''

    if (ratusan >= 100) {
      terbilang += terbilang1to19[Math.floor(ratusan / 100)] + ' ratus '
      ratusan %= 100
    }

    if (ratusan >= 20) {
      terbilang += terbilang1to19[Math.floor(ratusan / 10)] + ' puluh '
      ratusan %= 10
    }

    if (ratusan > 0) {
      if (ratusan < 10 && terbilang) terbilang += 'se'
      terbilang += terbilang1to19[ratusan]
    }

    return terbilang
  }

  let terbilangTotal = ''
  let index = 0

  while (number > 0) {
    const ribuan = number % 1000
    if (ribuan > 0) {
      const terbilangRatusan = terbilangSatuan(ribuan)
      if (terbilangTotal) {
        terbilangTotal = terbilangRatusan + ' ' + terbilangRibuan[index] + ' ' + terbilangTotal
      } else {
        terbilangTotal = terbilangRatusan + ' ' + terbilangRibuan[index]
      }
    }

    number = Math.floor(number / 1000)
    index++
  }

  return terbilangTotal || 'nol'
}

// Auto translate any text based on i18n config
export function translate(text: string) {
  if (te(text)) {
    return t(text)
  } else {
    return text
  }
}

export function encodeBase64(text: any) {
  return btoa(encodeURIComponent(text))
}

export function decodeBase64(text: any) {
  return decodeURIComponent(atob(text))
}


export const saveStoreApplication = (title: string, data: any) => {
  const jsonData = JSON.stringify(data)
  const encodedData = encodeBase64(jsonData)

  window.localStorage.setItem(title, encodedData)
}

export const getStoreApplication = (title: string) => {
  const data = window.localStorage.getItem(title) as string
  const decodedData = decodeBase64(data)

  return JSON.parse(decodedData)
}

export function onDownload(id: string, label: string, type: string, req: any) {
  const fileName = `${label}`

  const blob = new Blob([req], { type: type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName; // Set the correct file name
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function onDownloadCSV(response: any, filename: string = 'data.csv'){
  try {
    const blob = new Blob([response], { type: 'text/csv' });
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(fileUrl);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
}

export function formatAmount(amount) {
  if (!amount) return '';
  const numberFormat = new Intl.NumberFormat('id-ID');
  return `Rp. ${numberFormat.format(Number(amount)).replace(/,/g, '.')}`;
}

export function parseAmount(amount) {
  return amount.replace(/\D/g, '');
}

export const convertTimeToSeconds = (time: string): number => {
  const parts = time.split(':');
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  const seconds = parseInt(parts[2]);
  return (hours * 3600) + (minutes * 60) + seconds;
};


// Pick first letter of a word
export function extractInitials(name: string, wordCount: number = 2) {
  if (name) {
    let words = name.split(' ')
    words = words.slice(0, Math.min(words.length, wordCount))

    return words
    .map((word) => word[0])
    .join('')
    .toUpperCase()
  }
}

export const downloadFile = async (fileUrl: string, fileName: string) => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
        // Origin: window.location.origin,
      },
    });

    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download file error:', error);
  }
};
