import type { RouteRecordRaw } from "vue-router";

const appsRegisterRoute: Array<RouteRecordRaw> = [
  {
    path: "/register",
    name: "apps-register",
    component: () => import("@/views/apps/auth/register/RegisterBuffer.vue"),
    meta: {
      pageTitle: "register",
    },
  },
  {
    path: "/register-complete",
    name: "apps-register-complete",
    component: () => import("@/views/apps/auth/register/RegisterComplete.vue"),
    meta: {
      pageTitle: "register",
    },
  },
];

export default appsRegisterRoute;
