import type { RouteRecordRaw } from "vue-router";

const typeReceptionFoundationRoute: Array<RouteRecordRaw> = [
    {
        path: "/foundation/type-reception",
        name: "foundation-type-reception",
        component: () => import("@/views/foundation/typeReception/TypeReceptionList.vue"),
        meta: {
            pageTitle: "typeReception",
            breadcrumbs: ['foundation', 'typeReception'],
        },
    },
    {
        path: "/foundation/type-reception/detail",
        name: "foundation-type-reception-detail",
        component: () => import("@/views/foundation/typeReception/TypeReceptionList.vue"),
        meta: {
            pageTitle: "typeReception",
            breadcrumbs: ['foundation', 'typeReception', 'detail'],
        },
    },
];

export default typeReceptionFoundationRoute;
