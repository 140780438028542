import type { RouteRecordRaw } from "vue-router";

const billRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/bill",
        name: "bill",
        component: () => import("@/views/admin/bill/BillList.vue"),
        meta: {
            pageTitle: "bill",
            breadcrumbs: ["bill"],
        },
    },
    {
        path: "/admin/bill/detail",
        name: "bill-detail",
        component: () => import("@/views/admin/bill/BillDetail.vue"),
        meta: {
            pageTitle: "bill",
            breadcrumbs: ["bill", "detail"],
        },
    },
];

export default billRoute;
