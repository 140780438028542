import type { RouteRecordRaw } from "vue-router";

const classProgramRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/class-program",
        name: "admin-master-classProgram",
        component: () => import("@/views/admin/master/classProgram/ClassProgramList.vue"),
        meta: {
            pageTitle: "classProgram",
            breadcrumbs: ["masterData", "classProgram"],
        },
    },
    // {
    //     path: "/admin/master/class-program/create",
    //     name: "admin-master-classProgram-create",
    //     component: () => import("@/views/admin/master/classProgram/ClassProgramCreate.vue"),
    //     meta: {
    //         pageTitle: "classProgram",
    //         breadcrumbs: ["masterData", "classProgram", "create"]
    //     },
    // },
    // {
    //     path: "/admin/master/class-program/update",
    //     name: "admin-master-classProgram-update",
    //     component: () => import("@/views/admin/master/classProgram/ClassProgramUpdate.vue"),
    //     meta: {
    //         pageTitle: "classProgram",
    //         breadcrumbs: ["masterData", "classProgram", "update"]
    //     },
    // },
];

export default classProgramRoute;
