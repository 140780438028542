import type { RouteRecordRaw } from "vue-router";

const entranceRoute: Array<RouteRecordRaw> = [
    {
        path: "/admin/master/entrance",
        name: "admin-master-entrance",
        component: () => import("@/views/admin/master/entrance/EntranceList.vue"),
        meta: {
            pageTitle: "entrance",
            breadcrumbs: ["masterData", "entrance"],
        },
    },
    // {
    //     path: "/admin/master/entrance/create",
    //     name: "admin-master-entrance-create",
    //     component: () => import("@/views/admin/master/entrance/EntranceCreate.vue"),
    //     meta: {
    //         pageTitle: "entrance",
    //         breadcrumbs: ["masterData", "entrance", "create"],
    //     }
    // },
    // {
    //   path: "/admin/master/entrance/update",
    //   name: "admin-master-entrance-update",
    //   component: () => import("@/views/admin/master/entrance/EntranceUpdate.vue"),
    //     meta: {
    //         pageTitle: "entrance",
    //         breadcrumbs: ["masterData", "entrance", "update"],
    //     }
    // },
];

export default entranceRoute;
