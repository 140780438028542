import type { RouteRecordRaw } from "vue-router";

const progressFoundationRoute: Array<RouteRecordRaw> = [
    {
        path: "/foundation/progress",
        name: "progress",
        component: () => import("@/views/foundation/progress/ProgressList.vue"),
        meta: {
            pageTitle: "progress",
            breadcrumbs: ['foundation', 'progress'],
        },
    },
];

export default progressFoundationRoute;
