import type { RouteRecordRaw } from "vue-router";

const setupCmsPage: Array<RouteRecordRaw> = [
  {
    path: "/admin/setup-cms/page",
    name: "setup-cms-page",
    component: () => import("@/views/admin/setup/cms/page/PageList.vue"),
    meta: {
      pageTitle: "pageManagement",
      breadcrumbs: ["setupCMS", "pageManagement"],
    },
  },
  {
    path: "/admin/setup-cms/page/create",
    name: "setup-cms-page-create",
    component: () => import("@/views/admin/setup/cms/page/PageCreate.vue"),
    meta: {
      pageTitle: "createPage",
      breadcrumbs: ["setupCMS", "pageManagement", "create"],
    },
  },
];

export default setupCmsPage;
