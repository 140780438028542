import type { RouteRecordRaw } from "vue-router";

const failedMessageSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/failed-message",
    name: "system-admin-failed-message",
    component: () => import("@/views/sys_admin/failed_message/FailedMessageList.vue"),
    meta: {
      pageTitle: "failedMessage",
      breadcrumbs: ['systemAdmin', 'failedMessage'],
    },
  },
];

export default failedMessageSystemAdminRoute;
