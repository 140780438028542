import type { RouteRecordRaw } from "vue-router";

const inboundSystemAdminRoute: Array<RouteRecordRaw> = [
  {
    path: "/system-admin/inbound",
    name: "system-admin-inbound",
    component: () => import("@/views/sys_admin/inbound/InboundList.vue"),
    meta: {
      pageTitle: "inbound",
      breadcrumbs: ['systemAdmin', 'inbound'],
    },
  },
  {
    path: "/system-admin/inbound/detail",
    name: "system-admin-inbound-detail",
    component: () => import("@/views/sys_admin/inbound/InboundDetail.vue"),
    meta: {
      pageTitle: "applicant",
      breadcrumbs: ['systemAdmin', 'inbound', 'detail'],
    },
  },
];

export default inboundSystemAdminRoute;
